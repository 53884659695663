// src/components/EventTable.js

import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    ListItemText,
    MenuItem, Select,
    TextField,
    Typography,
} from '@mui/material';
import axios from './services/api';
import {useTranslation} from 'react-i18next';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import './i18n';
import WeekPlan from "./WeekPlan";
import DraggablePerson from "./DraggablePerson";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const EventTable = () => {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState({});
    const [filterPotential, setFilterPotential] = useState([]);
    const [filterProduct, setFilterProduct] = useState([]);
    const [filterArea, setFilterArea] = useState([]);
    const [filterLocation, setFilterLocation] = useState([]);
    const [filterFromDate, setFilterFromDate] = useState(null);
    const [filterToDate, setFilterToDate] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);

    const [schedule, setSchedule] = useState({});
    const [selectedDay, setSelectedDay] = useState("");
    const daysOfWeek = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];

    useEffect(() => {
        axios.get('/events').then((response) => {
            setEvents(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get('/week-plan').then((response) => {
            setSchedule(response.data);
        });
    }, [addDialogOpen]);

    const handleFilterChange = (event, setFilter) => {
        const {
            target: { value },
        } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value);
        setOpen({}); // Close all collapses when filters are changed
    };

    const handleFilterTextChange = (event, setFilter) => {
        const {
            target: { value },
        } = event;
        setFilter(value);
        setOpen({}); // Close all collapses when filters
    }

    const handleClearFilters = () => {
        setFilterPotential([]);
        setFilterProduct([]);
        setFilterArea([]);
        setFilterLocation([]);
        setFilterFromDate(null);
        setFilterToDate(null);
        setFilterName('');
        setOpen({}); // Close all collapses when filters are cleared
    };

    const filteredEvents = events.filter((event) => {
        const potentialMatch = filterPotential.length === 0 || filterPotential.includes(event.potential);
        const productMatch = filterProduct.length === 0 || filterProduct.some((product) => event.products.includes(product));
        const areaMatch = filterArea.length === 0 || filterArea.includes(event.area);
        const locationMatch = filterLocation.length === 0 || filterLocation.includes(event.location);
        const fromDateMatch = !filterFromDate || new Date(event.visitDate) >= new Date(filterFromDate);
        const toDateMatch = !filterToDate || new Date(event.visitDate) <= new Date(filterToDate);
        const nameMatch = !filterName || event.doctorName.toLowerCase().includes(filterName.toLowerCase());
        return potentialMatch && productMatch && areaMatch && locationMatch && fromDateMatch && toDateMatch && nameMatch;
    });

    const handleEventClick = (event) => {
        setInfoDialogOpen(true);
        setSelectedEvent(event);
    };

    const handleCloseDialog = () => {
        setSelectedEvent(null);
    };

    const sortedEvents = (events) => {
        return events.sort((a, b) => {
            if (orderBy === '') return 0;
            if (order === 'asc') {
                return a[orderBy] < b[orderBy] ? -1 : 1;
            }
            return a[orderBy] > b[orderBy] ? -1 : 1;
        });
    };

    const handleDeleteEvent = (event) => {
        setEventToDelete(event);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setEventToDelete(null);
    };

    const confirmDeleteEvent = () => {
        axios.delete(`/events/delete/${eventToDelete.id}`).then(() => {
            setEvents(events.filter((event) => event.id !== eventToDelete.id));
            setDeleteDialogOpen(false);
            setEventToDelete(null);
        });
    };

    const sortedLocations = filteredEvents.reduce((acc, event) => {
        const locationIndex = acc.findIndex((loc) => loc.location === event.location);
        if (locationIndex === -1) {
            acc.push({
                location: event.location,
                events: [event],
            });
        } else {
            acc[locationIndex].events.push(event);
        }
        return acc;
    }, []).sort((a, b) => b.events.length - a.events.length);

    const handleAddPerson = (person, day) => {
        setSchedule((prev) => {
            const updatedSchedule = { ...prev };

            if (!updatedSchedule[day]) {
                updatedSchedule[day] = {};
            }
            if (!updatedSchedule[day][person.location]) {
                updatedSchedule[day][person.location] = [];
            }

            updatedSchedule[day][person.location] = [
                ...updatedSchedule[day][person.location],
                person,
            ];

            axios.post('/week-plan', updatedSchedule);

            setAddDialogOpen(false);

            return updatedSchedule;
        });
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{margin: 4}}>

                <div style={{width: "100%"}}>
                    <Grid container spacing={2} alignItems="center" style={{marginBottom: "10px", width: "80%", float: "right"}}>
                        <Grid item xs={12} sm={2}>
                            <TextField
                              label={t('eventTable.filterByName')}
                              value={filterName}
                              onChange={(e) => handleFilterTextChange(e, setFilterName)}
                              variant="outlined"
                              fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                              select
                              label={t('eventTable.filterByProduct')}
                              value={filterProduct}
                              onChange={(e) => handleFilterChange(e, setFilterProduct)}
                              variant="outlined"
                              fullWidth
                              SelectProps={{
                                  multiple: true,
                                  renderValue: (selected) => selected.join(', '),
                              }}
                            >
                                {Array.from(new Set(events.flatMap((event) => event.products))).map((product) => (
                                  <MenuItem key={product} value={product}>
                                      <Checkbox checked={filterProduct.includes(product)}/>
                                      <ListItemText primary={product}/>
                                  </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                              select
                              label={t('eventTable.filterByArea')}
                              value={filterArea}
                              onChange={(e) => handleFilterChange(e, setFilterArea)}
                              variant="outlined"
                              fullWidth
                              SelectProps={{
                                  multiple: true,
                                  renderValue: (selected) => selected.join(', '),
                              }}
                            >
                                {Array.from(new Set(events.map((event) => event.area))).map((area) => (
                                  <MenuItem key={area} value={area}>
                                      <Checkbox checked={filterArea.includes(area)}/>
                                      <ListItemText primary={area}/>
                                  </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                              select
                              label={t('eventTable.filterByLocation')}
                              value={filterLocation}
                              onChange={(e) => handleFilterChange(e, setFilterLocation)}
                              variant="outlined"
                              fullWidth
                              SelectProps={{
                                  multiple: true,
                                  renderValue: (selected) => selected.join(', '),
                              }}
                            >
                                {Array.from(new Set(events.map((event) => event.location))).map((location) => (
                                  <MenuItem key={location} value={location}>
                                      <Checkbox checked={filterLocation.includes(location)}/>
                                      <ListItemText primary={location}/>
                                  </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                              select
                              label={t('eventTable.filterByPotential')}
                              value={filterPotential}
                              onChange={(e) => handleFilterChange(e, setFilterPotential)}
                              variant="outlined"
                              fullWidth
                              SelectProps={{
                                  multiple: true,
                                  renderValue: (selected) => selected.join(', '),
                              }}
                            >
                                {['ALTO', 'MEDIO', 'BAIXO'].map((potential) => (
                                  <MenuItem key={potential} value={potential}>
                                      <Checkbox checked={filterPotential.includes(potential)}/>
                                      <ListItemText primary={potential}/>
                                  </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <DatePicker
                              label={t('eventTable.filterFromDate')}
                              value={filterFromDate}
                              onChange={(date) => {
                                  setFilterFromDate(date);
                                  setOpen({}); // Close all collapses when filters are changed
                              }}
                              renderInput={(params) => <TextField {...params} fullWidth variant="outlined"/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <DatePicker
                              label={t('eventTable.filterToDate')}
                              value={filterToDate}
                              onChange={(date) => {
                                  setFilterToDate(date);
                                  setOpen({}); // Close all collapses when filters are changed
                              }}
                              renderInput={(params) => <TextField {...params} fullWidth variant="outlined"/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant="contained" color="primary" onClick={handleClearFilters} style={{marginTop: "10px"}}>
                                {t('eventTable.clearFilters')}
                            </Button>
                        </Grid>
                        <Typography variant="subtitle2" style={{marginLeft: "80px", marginTop: '15px'}}>
                            {filteredEvents.length}/{events.length}
                        </Typography>
                        <div style={{marginTop: "20px"}}>
                            <WeekPlan
                              events={[]} // Pass events if needed
                              schedule={schedule}
                              setSchedule={setSchedule} // Pass setSchedule to allow updating the schedule
                            />
                        </div>
                    </Grid>
                    <Grid container spacing={2} alignItems="center"
                          style={{
                              marginBottom: "10px",
                              width: "20%",
                              float: "left",
                              maxHeight: "800px", // Set a fixed height for the scrollable area
                              overflowY: "auto" // Enable scrolling within the Grid
                          }}
                    >
                        <DndProvider backend={HTML5Backend}>

                            <Box
                              display="flex"
                              flexWrap="wrap" // Allows wrapping to the next row if cards exceed container width
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              gap={2} // Space between items
                              sx={{marginTop: 1}}
                            >
                                {sortedLocations.map((locationGroup) => (
                                  <React.Fragment key={locationGroup.location}>
                                      {sortedEvents(locationGroup.events).map((event) => (
                                        <DraggablePerson
                                          key={event.id}
                                          person={event}
                                          handleEventClick={handleEventClick}
                                          handleDeleteEvent={handleDeleteEvent}
                                          handleAddEvent={(event) => {
                                              setSelectedEvent(event);
                                              setAddDialogOpen(true);
                                          }}
                                          event={event}
                                        />
                                      ))}
                                  </React.Fragment>
                                ))}
                            </Box>
                        </DndProvider>
                    </Grid>

                    <Dialog open={!!selectedEvent && addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                        <DialogTitle>{t('eventTable.selectTheDayOfTheWeek')}</DialogTitle>
                        <DialogContent>
                            {selectedEvent && (
                              <>
                                  <DialogContentText>
                                    <strong>{t('eventTable.doctorName')}: </strong>{selectedEvent.doctorName}
                                  </DialogContentText>
                                  <DialogContentText>
                                    <strong>{t('eventTable.area')}: </strong>{selectedEvent.area}
                                  </DialogContentText>
                                  <DialogContentText>
                                    <strong>{t('eventTable.location')}: </strong>{selectedEvent.location}
                                  </DialogContentText>
                                  <DialogContentText>
                                      <strong>{t('eventTable.products')}: </strong>{selectedEvent.products.join(', ')}
                                  </DialogContentText>
                              </>
                            )}
                            <Select
                              value={selectedDay}
                              onChange={handleDayChange}
                              style={{marginTop: "10px"}}
                              displayEmpty
                              fullWidth
                            >
                                <MenuItem value="" disabled>{t('eventTable.selectTheDayOfTheWeek')}</MenuItem>
                                {daysOfWeek.map((day) => (
                                  <MenuItem key={day} value={day}>{day}</MenuItem>
                                ))}
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddDialogOpen(false)} color="secondary">{t('eventTable.close')}</Button>
                            <Button onClick={() => handleAddPerson(selectedEvent, selectedDay)} color="primary" disabled={!selectedDay}>{t('eventTable.confirm')}</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={!!selectedEvent && infoDialogOpen} onClose={handleCloseDialog}>
                        <DialogTitle>{t('eventTable.eventDetails')}</DialogTitle>
                        <DialogContent>
                            {selectedEvent && (
                              <>
                                  <DialogContentText><strong>{t('eventTable.doctorName')}: </strong>{selectedEvent.doctorName}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.area')}: </strong>{selectedEvent.area}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.location')}: </strong>{selectedEvent.location}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.visitDate')}: </strong>{new Date(selectedEvent.visitDate).toLocaleDateString('pt-BR')}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.period')}: </strong>{selectedEvent.period}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.notes')}: </strong>{selectedEvent.notes}
                                  </DialogContentText>
                                  <DialogContentText><strong>{t('eventTable.products')}: </strong>{selectedEvent.products.join(', ')}
                                  </DialogContentText>
                              </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">{t('eventTable.close')}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                        <DialogTitle>{t('eventTable.confirmDeleteTitle')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('eventTable.confirmDeleteMessage', {doctorName: eventToDelete?.doctorName})}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} color="primary">
                                {t('eventTable.cancel')}
                            </Button>
                            <Button onClick={confirmDeleteEvent} color="primary">
                                {t('eventTable.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
        </LocalizationProvider>
);
};

export default EventTable;