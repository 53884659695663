import React from "react";
import {useDrag} from "react-dnd";
import {IconButton, Paper} from "@mui/material";
import {AddSharp, Delete as DeleteIcon, Info as InfoIcon} from '@mui/icons-material';

function DraggablePerson({person, handleEventClick, handleDeleteEvent, handleAddEvent, event}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "PERSON",
    item: person,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      style={{
        padding: "10px",
        margin: "5px",
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        width: "150px", // Set a fixed width for each card
        textAlign: "center",
        backgroundColor: person.potential === "BAIXO" ? "#fdedec"
          : person.potential === "MEDIO" ? "#fbefde"
            : "#e9f8e1",
      }}
      elevation={5}
      title={person.doctorName}
    >
      <strong
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "0.8em",
        }}
      >{person.doctorName}</strong>
      <div
        style={{
          justifyContent: "space-between",
          marginBottom: "0px",
          fontSize: "0.7em",
        }}
      >{person.area}</div>
      <div
        style={{
          justifyContent: "space-between",
          marginBottom: "5px",
          fontSize: "0.7em",
        }}
      >{person.location}</div>
      <IconButton aria-label="expand row" size="small" onClick={(e) => { e.stopPropagation(); handleEventClick(event); }}>
        <InfoIcon />
      </IconButton>
      <IconButton aria-label="delete" size="small" onClick={(e) => { e.stopPropagation(); handleDeleteEvent(event); }}>
        <DeleteIcon />
      </IconButton>
      <IconButton aria-label="add" size="small" onClick={(e) => { e.stopPropagation(); handleAddEvent(event); }}>
        <AddSharp />
      </IconButton>
    </Paper>
  );
}

export default DraggablePerson;