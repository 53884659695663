// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    pt: {
        translation: {
            "eventTable": {
                "title": "Tabela de Eventos",
                "doctorName": "Nome do Doutor",
                "area": "Área",
                "visitDate": "Data da Visita",
                "products": "Produtos",
                "notes": "Notas",
                "period": "Período",
                "location": "Localização",
                "filterByPotential": "Potencial",
                "filterByProduct": "Filtrar por Produto",
                "filterByArea": "Filtrar por Área",
                "filterByLocation": "Localização",
                "filterFromDate": "Início",
                "filterByName" : "Filtrar por Nome",
                "filterToDate": "Fim",
                "clearFilters": "Limpar Filtros",
                "totalEventsLoaded": "Total de Eventos Carregados",
                "totalEventsFiltered": "Total de Eventos Filtrados",
                "eventDetails": "Detalhes do Evento",
                "confirm": "Confirmar",
                "close": "Fechar",
                "details": "",
                "confirmDeleteTitle": "Confirmar Exclusão",
                "confirmDeleteText": "Tem certeza que deseja excluir este evento?",
                "delete": "Excluir",
                "cancel": "Cancelar",
                "confirmDeleteMessage": "Tem certeza que deseja excluir este evento?",
                "selectTheDayOfTheWeek": "Selecione o dia da semana",
            }
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'pt',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;